import Helmet from "react-helmet"
import React from "react"
import Styles from "./css/pdfeditor.module.scss"
import CtaDisclosureNewtab from "@tightrope/ctadisclosurenewtab"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import { StaticImage } from "gatsby-plugin-image"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single.js"

class Imgeditor extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    return (
        <div id = {Styles.pdfeditor}>
          <div className = {Styles.container}>
            <svg viewBox="0 0 500 500"
            preserveAspectRatio="xMinYMin meet">
              <path d="M0, 125 C150, 225 300,
              0 500, 150 L500, 00 L0, 0 Z"
              style={{stroke: "none", fill:"white"}}>
              </path>
            </svg>
              <StaticImage
                src="../../../static/img/logo.png"
                alt="PDFtoDOC"
                placeholder="blurred"
                layout="fixed"
                width={150}
                height={38}
                id={Styles.topLogo}
              />
              <div className = {Styles.centered}>
              <h1>Edit, Sign, and Protect Your PDFs!<br/>Free Secure, Easy to Use</h1>
              <h4>Free Newtab Browser Extension</h4>
              <button onClick = {() => window.triggerInstall()} className={Styles.install}
              >Continue to Chrome Web Store</button>
              <CtaDisclosureNewtab></CtaDisclosureNewtab>
              </div>
              <div className={Styles.gridContainer}>
                <div className={Styles.gridItem}>

                  <div className={Styles.feature}>
                    <StaticImage
                      src="../../../static/img/install.svg"
                      alt="PDFtoDOC"
                      placeholder="blurred"
                      layout="fixed"
                      width={30}
                      height={30}
                      id={Styles.icons}
                    />
                    <p className={Styles.descrip}>Add <strong>FromPDFtoDoc</strong> from the Chrome Web Store in seconds.</p>
                  </div>

                  <div className={Styles.feature}>
                    <StaticImage
                      src="../../../static/img/secure.svg"
                      alt="PDFtoDOC"
                      placeholder="blurred"
                      layout="fixed"
                      width={30}
                      height={30}
                      id={Styles.icons}
                    />
                    <p className={Styles.descrip}>Securely upload or drag & drop to edit your files for free.</p>
                    </div>
                  <div className={Styles.feature}>
                    <StaticImage
                      src="../../../static/img/edit.svg"
                      alt="PDFtoDOC"
                      placeholder="blurred"
                      layout="fixed"
                      width={30}
                      height={30}
                      id={Styles.icons}
                    />
                    <p className={Styles.descrip}>Edit, Sign, Protect, Fill and more!</p>
                    </div>
                </div>

                <div className={Styles.gridItem}>
                  <StaticImage
                    src="../../../static/img/pdflive.png"
                    alt="PDFtoDOC"
                    placeholder="blurred"
                    layout="fixed"
                    width={271}
                    height={185}
                    id={Styles.pdflive}
                  />
                </div>
              </div>

              <div className = {Styles.centered}>
              <h3 className={Styles.try}>Try it Risk-Free!</h3>
              <button onClick = {() => window.triggerInstall()} className={Styles.install}
              >Continue to Chrome Web Store</button>
              </div>
            <FooterlinksSingle></FooterlinksSingle>
          </div>
        </div>
    )
  }
}
export default Imgeditor
