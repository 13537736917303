import Helmet from "react-helmet"
import React from "react"
import Imgeditor from "src/components/imgeditor"


export default function Optional() {
  return(
    <section>
      <Helmet>
      <style type="text/css">{`
        body {
          overflow: scroll;
          padding: 0;
          margin: 0;
          height: 100%;
          width: 100%;
          background-image: linear-gradient(to left top, #faf1f7, #f8f0f8, #f5eff9, #f1eefa, #edeefa, #ebeffb, #e8f0fb, #e6f1fb, #e6f3fb, #e6f5fb, #e7f6fa, #e9f8fa);
          background-position: bottom right;
          background-size: cover;
          background-attachment: fixed;
        }

        html {
          min-height: 100%;
        }
    `}</style>

        <title>Edit PDFS for Free! - frompdftodoc.com</title>
      </Helmet>
      <Imgeditor></Imgeditor>
    </section>
  )
}
